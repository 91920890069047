
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";

.faucet-bootstrap {
    @import "../../../node_modules/bootstrap/scss/nav";
    @import "../../../node_modules/bootstrap/scss/navbar";
}

.faucethub-header-container {
    height: 48px;
}

body a.github-fork-ribbon.fixed {
    top: 48px;
    z-index: 1000;
}

body .faucet-wrapper {
    min-height: calc(100% - 48px);
}

body .faucethub-header .navbar {
    position: fixed;
    padding: 4px 0;
    background-color: white;
    box-shadow: 0 0.5em 1.5em rgba(0, 0, 0, .1), 0 0.125em 0.5em rgba(0, 0, 0, .15);
    z-index: 1010;
}

body .faucethub-header .navbar .navbar-brand {
    margin-right: 40px;
}


